<template>
  <div
    v-if="active"
    :class="[
      alertVariants({ color, padding }),
      slots.default || confirmButton || closeButton ? 'justify-between py-2' : 'justify-center py-3.5'
    ]">
    <div class="text-sm">{{ description }}</div>
    <div class="flex gap-x-3">
      <slot />
      <Button v-if="confirmButton" variant="outline" :color value="확인" @click="$emit('confirm')" />
      <Button v-if="closeButton" variant="outline" :color value="닫기" @click="handleClose" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { alertVariants } from '.'
import type { AlertProps } from './types'

const props = withDefaults(defineProps<AlertProps>(), {
  color: 'slate',
  padding: 'md',
  closeButton: false,
  confirmButton: false,
  autoClose: false,
  timeout: 5000
})

const emits = defineEmits(['confirm', 'close'])
const slots = useSlots()
const active = defineModel<boolean>({ default: false })

function setTimer() {
  setTimeout(() => {
    active.value = false
  }, props.timeout)
}

const handleClose = () => {
  active.value = false
  emits('close')
}

if (props.autoClose) {
  setTimer()
  watch(active, (active) => {
    if (active) setTimer()
  })
}
</script>
