import { cva } from 'class-variance-authority'

export { default as Alert } from './Alert.vue'
export { default as AlertBanner } from './AlertBanner.vue'
export const alertVariants = cva('flex items-center', {
  variants: {
    color: {
      blue: 'bg-blue-100 text-blue-600',
      slate: 'bg-slate-100 text-slate-600',
      red: 'bg-red-100 text-red-600',
      yellow: 'bg-yellow-100 text-yellow-600',
      green: 'bg-green-100 text-green-600'
    },
    padding: {
      md: 'px-12',
      sm: 'px-6'
    }
  },
  defaultVariants: {
    color: 'slate',
    padding: 'md'
  }
})
